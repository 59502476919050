import React from 'react';
import { useState } from 'react';

import { ChevronDown } from '../../../common/components/icons/ChevronDown';
import { ChevronUp } from '../../../common/components/icons/ChevronUp';
import { useGetVesselCompaniesQuery } from '../../../store/apis/ihs-vessel-data-api';
import { useGetDetailedShipdataQuery, useGetVesselPositionsForSelectedDatesQuery } from '../hooks';
import ReactJson from '@microlink/react-json-view';
import {
  useInstallationsGetQuery,
  useInstallationsInstallationIdEquipmentsGetQuery,
  useInstallationsInstallationIdPartnersGetQuery,
} from '../../../store/apis/installation-api';
import { useAppSelector, useGetFilteredEvents } from '../../../common/hooks';
import { useGetPortEventsPortCallsQuery } from '../../../store/apis/ais-api';

type Props = {
  imo: string;
  className?: string;
};

export const DataSources: React.FC<Props> = ({ imo, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const installationQuery = useInstallationsGetQuery({ imoNumber: Number(imo), fields: 'id' });
  const installationId = installationQuery.currentData?.[0]?.id;
  const { startDate, endDate } = useAppSelector((state) => state.vesselMapDateSelect);

  const companiesQuery = useGetVesselCompaniesQuery({
    lrimoshipno: `eq.${imo}`,
    select: 'role_code,role_name,owcode,fullcompanyname,country_code',
  });
  const shipDataQuery = useGetDetailedShipdataQuery(Number(imo));

  const equipmentQuery = useInstallationsInstallationIdEquipmentsGetQuery(
    { installationId: installationId?.toString() as string },
    { skip: !installationId }
  );
  const partnersQuery = useInstallationsInstallationIdPartnersGetQuery(
    { installationId: installationId?.toString() as string },
    { skip: !installationId }
  );
  const portData = useGetPortEventsPortCallsQuery(
    { imo: Number(imo) || undefined, from: startDate, to: endDate, limit: 100 },
    { skip: !imo }
  );
  const { anomalies, casualties, drydockings } = useGetFilteredEvents();
  const positionsQuery = useGetVesselPositionsForSelectedDatesQuery(Number(imo));

  return (
    <div className={`RelatedCompanies mr-2 mt-3 ${className ? className : ''}`}>
      <div className="flex flex-row items-center justify-between border-b border-secondary pb-1 mb-2">
        <div className="flex flex-row items-center">
          <div onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <ChevronUp className="h-5 w-5 mr-2 cursor-pointer" />
            ) : (
              <ChevronDown className="h-5 w-5 mr-2 cursor-pointer" />
            )}
          </div>
          <span className="text-lg cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
            Data Sources
          </span>
        </div>
      </div>
      {isOpen ? (
        <div className="w-full">
          <div className="border-b border-secondary pb-1 mb-2">
            <div className="flex justify-between">
              <h3 className="text-lg my-2">AIS Vessel Location API</h3>
              <div className="bg-secondary rounded py-1 px-2 text-xs self-center">
                Source:{' '}
                <a
                  className="text-accent hover:underline"
                  target="_blank"
                  href="https://developers.wartsila.com/api-details#api=ais"
                  rel="noreferrer"
                  title="Link to API developers portal"
                >
                  API Portal
                </a>
              </div>
            </div>
            {positionsQuery.currentData && (
              <>
                <p className="">Vessel Positions Request:</p>
                <p className="text-accent-content text-sm break-words">
                  https://api.wartsila.com/ais/vessel-positions?imo={imo}&mode=all&from=
                  {positionsQuery.originalArgs?.from}&to={positionsQuery.originalArgs?.from}
                  &downsample=auto&fields=ports.last_port%2Cports.current_port%2Cports.next_port%2Cpositions.status_desc%2Cpositions.source%2Cpositions.timestamp%2Cpositions.latitude%2Cpositions.longitude%2Cpositions.speed%2Cpositions.heading%2Cpositions.course%2Cpositions.rate_of_turn%2Cpositions.voyage.draught%2Cpositions.voyage.eta%2Cpositions.voyage.destination&limit=5000
                </p>
                <p className="">Response:</p>
                <ReactJson
                  src={positionsQuery.currentData as object}
                  theme="pop"
                  displayDataTypes={false}
                  name={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  collapsed={0}
                />
              </>
            )}
            {portData.currentData && (
              <>
                <p className="">Port Calls Request:</p>
                <p className="text-accent-content text-sm break-words">
                  https://api.wartsila.com/ais/port-calls?imo={imo}&from={startDate}&to={endDate}&limit=100
                </p>
                <p className="">Response:</p>
                <ReactJson
                  src={portData.currentData as object}
                  theme="pop"
                  displayDataTypes={false}
                  name={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  collapsed={0}
                />
              </>
            )}
          </div>
          <div className="border-b border-secondary pb-1 mb-2">
            <div className="flex justify-between">
              <h3 className="text-lg my-2">Vessel Events Api</h3>
              <div className="bg-secondary rounded py-1 px-2 text-xs self-center">
                Source:{' '}
                <a
                  className="text-accent hover:underline"
                  target="_blank"
                  href="https://developers.wartsila.com/api-details#api=vessel-event"
                  rel="noreferrer"
                  title="Link to API developers portal"
                >
                  API Portal
                </a>
              </div>
            </div>
            {anomalies.currentData && (
              <>
                <p className="">Anomalies Request:</p>
                <p className="text-accent-content text-sm break-words">
                  https://api.wartsila.com/vessel-event/anomalies?imo_number=eq.{imo}&order=start_datetime.desc
                </p>
                <p className="">Response:</p>
                <ReactJson
                  src={anomalies.currentData as object}
                  theme="pop"
                  displayDataTypes={false}
                  name={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  collapsed={0}
                />
              </>
            )}
            {casualties.currentData && (
              <>
                <p className="">Casualties Request:</p>
                <p className="text-accent-content text-sm break-words">
                  https://api.wartsila.com/vessel-event/casualties?imo_number=eq.{imo}&order=casualty_date.desc
                </p>
                <p className="">Response:</p>
                <ReactJson
                  src={casualties.currentData as object}
                  theme="pop"
                  displayDataTypes={false}
                  name={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  collapsed={0}
                />
              </>
            )}
            {drydockings.currentData && (
              <>
                <p className="">Drydocking Request:</p>
                <p className="text-accent-content text-sm break-words">
                  https://api.wartsila.com/vessel-event/drydockings?imo_number=eq.{imo}&order=update_datetime.desc
                </p>
                <p className="">Response:</p>
                <ReactJson
                  src={drydockings.currentData as object}
                  theme="pop"
                  displayDataTypes={false}
                  name={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  collapsed={0}
                />
              </>
            )}
          </div>

          <div className="border-b border-secondary pb-1 mb-2">
            <div className="flex justify-between">
              <h3 className="text-lg my-2">Installation Master Data Read</h3>
              <div className="bg-secondary rounded py-1 px-2 text-xs self-center">
                Source:{' '}
                <a
                  className="text-accent hover:underline"
                  target="_blank"
                  href="https://developers.wartsila.com/api-details#api=installation"
                  rel="noreferrer"
                  title="Link to API developers portal"
                >
                  API Portal
                </a>
              </div>
            </div>
            {!!equipmentQuery?.currentData && (
              <>
                <p className="">Equipments Request:</p>
                <p className="text-accent-content text-sm break-words">
                  https://api-dev.wartsila.com/installation/installations/{installationId}/equipments?
                </p>
                <p className="">Response:</p>
                <ReactJson
                  src={equipmentQuery.currentData as object}
                  theme="pop"
                  displayDataTypes={false}
                  name={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  collapsed={0}
                />
              </>
            )}
            {partnersQuery.currentData && (
              <>
                <p className="">Partners Request:</p>
                <p className="text-accent-content text-sm break-words">
                  https://api-dev.wartsila.com/installation/installations/{installationId}/partners?
                </p>
                <p className="">Response:</p>
                <ReactJson
                  src={partnersQuery.currentData as object}
                  theme="pop"
                  displayDataTypes={false}
                  name={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  collapsed={0}
                />
              </>
            )}
          </div>
          <div className="border-b border-secondary pb-1 mb-2">
            <div className="flex justify-between">
              <h3 className="text-lg my-2">IHS Maritime Vessel Data API</h3>
              <div className="bg-secondary rounded py-1 px-2 text-xs self-center">
                Source:{' '}
                <a
                  className="text-accent hover:underline"
                  target="_blank"
                  href="https://developers.wartsila.com/api-details#api=ihs-vessel-data"
                  rel="noreferrer"
                  title="Link to API developers portal"
                >
                  API Portal
                </a>
              </div>
            </div>
            <p className=""> Shipdata Request:</p>
            <p className="text-accent-content text-sm break-words">
              https://api.wartsila.com/ihs-vessel-data/shipdata?lrimoshipno=eq.{imo}
              &select=lrimoshipno%2Cshipname%2Cshiptypelevel4%2Cregisteredowner%2Cshipmanager%2Cyearofbuild%2Cclassificationsociety%2Cflagname%2Cflagcodes%28iso2%29%2Cstatcode5%2Cmaritimemobileserviceidentitymmsinumber%2Cdeathdate%2Cshipstatus
            </p>
            {companiesQuery.currentData && (
              <>
                <p className="">Response:</p>
                <ReactJson
                  src={shipDataQuery.currentData as object}
                  theme="pop"
                  displayDataTypes={false}
                  name={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  collapsed={0}
                />
              </>
            )}
            <p className="">Vessel Companies Request:</p>
            <p className="text-accent-content text-sm break-words">
              https://api.wartsila.com/ihs-vessel-data/vessel_companies?lrimoshipno=eq.{imo}
              &select=role_code%2Crole_name%2Cowcode%2Cfullcompanyname%2Ccountry_code
            </p>
            {companiesQuery.currentData && (
              <>
                <p className="">Response:</p>
                <ReactJson
                  src={companiesQuery.currentData as object}
                  theme="pop"
                  displayDataTypes={false}
                  name={false}
                  displayObjectSize={false}
                  enableClipboard={false}
                  collapsed={0}
                />
              </>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
