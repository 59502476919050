import dayjs from 'dayjs';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TimeRangeOption } from '../../common/types';
import { addSearchParamsToUrl, formatTimeRangeOptionForUrl } from '../../common/utils';
import { timeRangeOptions } from './filter-options';
import { getStateFromUrl } from './utils';

type setSelectedTimeRangeOptionAndUpdateUrlArg = {
  timeRangeOption: VesselMapDateSelectState['selectedTimeRangeOption'];
};

export const setSelectedTimeRangeOptionAndUpdateUrl = createAsyncThunk<void, setSelectedTimeRangeOptionAndUpdateUrlArg>(
  'vesselDetailsView/setSelectedTimeRangeOptionAndUpdateUrl',
  async (arg, { dispatch }) => {
    dispatch(setSelectedTimeRangeOption(arg.timeRangeOption));
    if (!arg.timeRangeOption) {
      return;
    }

    const urlTime = formatTimeRangeOptionForUrl(arg.timeRangeOption);
    if (!urlTime) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    params.delete('from');
    params.delete('to');
    params.set('time', urlTime);
    addSearchParamsToUrl(params);
  }
);

type setDatesAndUpdateUrlArg = {
  startDate: string;
  endDate: string;
  nullUrl?: boolean;
};

export const setDatesAndUpdateUrl = createAsyncThunk<void, setDatesAndUpdateUrlArg>(
  'vesselDetailsView/setDatesAndUpdateUrl',
  async (arg, { dispatch }) => {
    dispatch(setDates(arg));
    const params = new URLSearchParams(window.location.search);
    params.delete('time');
    if (arg.nullUrl) {
      params.delete('from');
      params.delete('to');
    } else {
      params.set('from', dayjs(arg.startDate).format('YYYY-MM-DD'));
      params.set('to', dayjs(arg.endDate).format('YYYY-MM-DD'));
    }
    addSearchParamsToUrl(params);
  }
);

type setDatesAndNullUrlArg = {
  startDate: string;
  endDate: string;
};

export const setDatesAndNullUrl = createAsyncThunk<void, setDatesAndNullUrlArg>(
  'vesselDetailsView/setDatesAndNullUrl',
  async (arg, { dispatch }) => {
    dispatch(setDates(arg));
    const nullParams = new URLSearchParams();
    addSearchParamsToUrl(nullParams);
  }
);

export type VesselMapDateSelectState = {
  timeRangeOptions: TimeRangeOption[];
  selectedTimeRangeOption: TimeRangeOption | null;
  startDate?: string;
  endDate?: string;
  key?: string;
  show: boolean;
};

const initialState: VesselMapDateSelectState = {
  timeRangeOptions,
  selectedTimeRangeOption: null,
  startDate: dayjs().format('YYYY-MM-DD'),
  endDate: dayjs().format('YYYY-MM-DD'),
  key: 'selection',
  show: false,
};

const initialStateFromUrl = getStateFromUrl(initialState);

const slice = createSlice({
  name: 'vesselMapDateSelect',
  initialState: initialStateFromUrl,
  reducers: {
    setDates(
      state,
      action: PayloadAction<{
        startDate?: VesselMapDateSelectState['startDate'];
        endDate?: VesselMapDateSelectState['endDate'];
      }>
    ) {
      state.startDate = action.payload.startDate;
      state.endDate = action.payload.endDate;
    },
    setSelectedTimeRangeOption(state, action: PayloadAction<VesselMapDateSelectState['selectedTimeRangeOption']>) {
      state.selectedTimeRangeOption = action.payload;
    },
    toggleShow(state) {
      state.show = !state.show;
    },
  },
});

export const { setDates, setSelectedTimeRangeOption, toggleShow } = slice.actions;
export const { reducer } = slice;
