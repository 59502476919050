import { LngLatLike } from 'mapbox-gl';
import { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '../../common/hooks';
import {
  useGetVesselPositionsAreaBoxQuery,
  useGetVesselPositionsAreaCircleQuery,
  useGetVesselPositionsPortQuery,
  VesselPosition,
} from '../../store/apis/ais-api';
import { GeoPoint, GetPlacesApiArg, useGetPlacesQuery } from '../../store/apis/port-api';
import { PortPageState, PortStatus, PortVesselFilterOptions, setUnlocode } from './slice';

const defaultQueryParams: Partial<GetPlacesApiArg> = {
  limit: '10',
  status: 'neq.deleted',
};

export const useSearchPortsQuery = (input: string) => {
  const queryParams = !!parseInt(input)
    ? { ...defaultQueryParams, id: `eq.${input}` }
    : { ...defaultQueryParams, or: `(name.ilike.*${input}*,unlocode.ilike.*${input}*)` };
  return useGetPlacesQuery({ ...queryParams }, { skip: !input });
};

export const useGetPortInformation = (input: string) => {
  const dispatch = useAppDispatch();
  const portQuery = useGetPlacesQuery(Number(input) ? { id: `eq.${input}` } : { unlocode: `eq.${input}` });
  useEffect(() => {
    if (portQuery.isFetching) {
      return;
    }

    dispatch(setUnlocode(portQuery.data?.[0]?.unlocode));
  }, [dispatch, portQuery.data, portQuery.isFetching]);
  return portQuery;
};

const filterFunction = (vessel: VesselPosition, state: PortPageState, portStage?: PortStatus) => {
  const now = new Date();
  now.setHours(new Date().getHours() - 24);
  if (state.portVesselFilter.value === PortVesselFilterOptions.Wartsila && !vessel.installation_id) {
    return false;
  }
  if (state.portVesselFilter.value === PortVesselFilterOptions.IMO && !vessel.imo) {
    return false;
  }
  if (portStage === PortStatus.Arriving && vessel.ports?.current_port?.unlocode === vessel.ports?.next_port?.unlocode)
    return false;
  if (portStage === PortStatus.Departing && vessel.ports?.current_port?.unlocode === vessel.ports?.last_port?.unlocode)
    return true;
  return (
    vessel.positions?.[0].status_id !== 99 &&
    !!vessel.positions?.[0].timestamp &&
    new Date(vessel.positions?.[0].timestamp) > now
  );
};

export const useGetVesselsAtPort: () => [VesselPosition[] | undefined, boolean] = () => {
  const state = useAppSelector((state) => state.portPage);
  const query = useGetVesselPositionsPortQuery({ current: state.unlocode }, { skip: !state.unlocode });
  const data = query.currentData?.results?.filter((vessel) => filterFunction(vessel, state, PortStatus.InPort));
  return [data, query.isFetching];
};

export const useGetVesselsArrivingToPort: () => [VesselPosition[] | undefined, boolean] = () => {
  const state = useAppSelector((state) => state.portPage);
  const query = useGetVesselPositionsPortQuery({ next: state.unlocode }, { skip: !state.unlocode });
  const data = query.currentData?.results?.filter((vessel) => filterFunction(vessel, state, PortStatus.Arriving));
  return [data, query.isFetching];
};

export const useGetVesselsDepartingFromPort: () => [VesselPosition[] | undefined, boolean] = () => {
  const state = useAppSelector((state) => state.portPage);
  const query = useGetVesselPositionsPortQuery({ last: state.unlocode }, { skip: !state.unlocode });
  const data = query.currentData?.results?.filter((vessel) => filterFunction(vessel, state, PortStatus.Departing));
  return [data, query.isFetching];
};

export const useGetVesselsInArea: () => [VesselPosition[] | undefined, boolean] = () => {
  const state = useAppSelector((state) => state.portPage);
  const query = useGetVesselPositionsAreaCircleQuery(
    {
      latitude: state.locationCoordinates?.latitude || 0,
      longitude: state.locationCoordinates?.longitude || 0,
      radius: 10,
    },
    { skip: !state.locationCoordinates }
  );
  const data = query.currentData?.results?.filter((vessel) => filterFunction(vessel, state));
  return [data, query.isFetching];
};

export const useGetVesselsInBox: () => [VesselPosition[] | undefined, boolean] = () => {
  const state = useAppSelector((state) => state.portPage);
  const query = useGetVesselPositionsAreaBoxQuery(
    {
      maxLatitude: state.locationBoxCoordinates?.maxLat as number,
      minLatitude: state.locationBoxCoordinates?.minLat as number,
      maxLongitude: state.locationBoxCoordinates?.maxLon as number,
      minLongitude: state.locationBoxCoordinates?.minLon as number,
    },
    { skip: !state.locationBoxCoordinates }
  );
  const data = query.currentData?.results?.filter((vessel) => filterFunction(vessel, state));
  return [data, query.isFetching];
};

export const useRepositionMapWhenPositionsChange = (portPosition?: GeoPoint) => {
  const map = useAppSelector((state) => state.portPage.mapInstance);
  const portId = useAppSelector((state) => state.portPage.unlocode);
  const locationCoordinates = useAppSelector((state) => state.portPage.locationCoordinates);

  useMemo(() => {
    if (!portPosition?.coordinates?.[0]) {
      if (locationCoordinates && map) {
        setTimeout(() => {
          // Run code in event queue in order to make the map get the updated DOM container size when running .resize()
          map.resize();
          map.flyTo({
            center: [locationCoordinates!.longitude, locationCoordinates!.latitude],
            zoom: 10,
            essential: true,
          });
        });
      }
      return;
    }

    const offset = portId ? 0.2 : 25;
    const southWesternCoordinates: LngLatLike = [
      portPosition.coordinates[0] - offset,
      Math.max(portPosition.coordinates[1] - offset, -90),
    ];
    const northEasternCoordinates: LngLatLike = [
      portPosition.coordinates[0] + offset,
      Math.min(portPosition.coordinates[1] + offset, 90),
    ];

    map?.fitBounds([southWesternCoordinates, northEasternCoordinates], { speed: 2, zoom: 11 });
  }, [map, portPosition, locationCoordinates, portId]);
};
